import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import showMsg from "@/utils/message";
import Cookies from "js-cookie";
import request from "@/utils/request";
import i18n from "@/locales/i18n";

const t = i18n.global.t;

// 正式 /en 重定向
const hostname = ["visitor.sialchina.cn", "visitor.sialchina.com"];
const website = {
  cn: "www.sialchina.cn",
  en: "www.sialchina.com",
};
const host_domain = {
  cn: "visitor.sialchina.cn",
  en: "visitor.sialchina.com",
};


if(window.location.pathname.indexOf("/en") == 0 && hostname.includes(window.location.hostname)) {
  window.location.href = process.env.VUE_APP_EN_HOST + window.location.pathname.replace(/^(\/en)/,"");
}

let prefix = "";
let currLang = "cn";
if ( window.location.pathname.indexOf("/en") == 0 ) {
  prefix = "/en";
  currLang = "en";
}
if (window.location.hostname == "visitor.sialchina.com") {
  prefix = "";
  currLang = "en";
}

const Layout = () => import(`@/views/visitor/${currLang}/Layout/Index.vue`);
const Login = () => import(`@/views/visitor/${currLang}/Auth/Login.vue`);
const Reg = () => import(`@/views/visitor/${currLang}/Auth/Reg.vue`);

/**
 * roles: ["visitor"] 页面可访问角色设置 visitor(观众、媒体、特邀买家): 是预登记会员； ordinary: 普通会员; vip
 */

const routes = [
  {
    path: `${prefix}/wechat`,
    name: "wechat",
    component: () => import(`@/views/visitor/${currLang}/Wechat/index.vue`),
    hidden: true,
  },
  {
    path: `${prefix}/wxbind`,
    name: "wxbind",
    component: () => import(`@/views/visitor/${currLang}/Auth/WxBinding.vue`),
    hidden: true,
  },

  {
    path: `${prefix}/logintest`,
    name: "logintest",
    component: () => import(`@/views/visitor/${currLang}/Auth/Logintest.vue`),
    hidden: true,
  },

  { path: `${prefix}/login`, name: "login", component: Login, hidden: true },
  { path: `${prefix}/reg`, name: "reg", component: Reg, hidden: true },
  // 301重定向到新的路径
  {
    path: `${prefix}/login.html`,
    redirect: `${prefix}/login`,
  },
  {
    path: `${prefix}/visitor/login`,
    redirect: `${prefix}/login`,
  },
  {
    path: `${prefix}/visitor/login.html`,
    redirect: `${prefix}/login`,
  },
  {
    path: `${prefix}/reg.html`,
    redirect: `${prefix}/reg`,
  },
  {
    path: `${prefix}/visitor/reg`,
    redirect: `${prefix}/reg`,
  },
  {
    path: `${prefix}/visitor/reg.html`,
    redirect: `${prefix}/reg`,
  },

  // VIP
  {
    path: `${prefix}/reg/vip/:recommend_user_id(\\d+)`,
    name: "regVip",
    component: Reg,
    hidden: true,
  },
  // 特邀买家
  {
    path: `${prefix}/matchme/reg`,
    name: "regInvite",
    component: Reg,
    hidden: true,
  },
  {
    path: `${prefix}/forget`,
    name: "forget",
    component: () => import(`@/views/visitor/${currLang}/Auth/Forget.vue`),
    hidden: true,
  },
  {
    path: `${prefix}/reg_survey/:expo_area_ids`,
    name: "reg_survey",
    component: () => import(`@/views/visitor/${currLang}/Auth/Survey.vue`),
    hidden: true,
  },
  {
    path: `${prefix}/reg_finish/:expo_area_ids`,
    name: "reg_finish",
    component: () => import(`@/views/visitor/${currLang}/Auth/Finish.vue`),
    hidden: true,
  },
  // 万能码绑定门票
  {
    path: `/universal`,
    name: "regUniversal",
    component: () => import(`@/views/visitor/cn/Auth/UniversalCodeReg.vue`),
    hidden: true,
  },
  {
    path: `${prefix}/policy`,
    name: "policy",
    component: () => import(`@/views/visitor/${currLang}/Auth/PolicyPage.vue`),
    hidden: true,
  },
  {
    path: prefix == "" ? "/" : prefix,
    component: Layout,
    children: [
      {
        path: "",
        component: () => import(`@/views/visitor/${currLang}/Index/Index.vue`),
        name: "Index",
        meta: {
          title: t("meta.概览"),
          icon: "icon-shouye",
          chkUserInfo: true,
          breadcrumb: false,
        },
      },
    ],
  },

  // 信息订阅落地页
  {
    path: `${prefix}/info_subscription`,
    name: "info_subscription",
    component: () =>
      import(`@/views/visitor/${currLang}/Subscription/Landing.vue`),
    hidden: true,
  },

  // 支付
  {
    path: `${prefix}/alipay/onsiteforums/:ticket_id(\\d+)`,
    component: () => import(`@/components/OnsiteForumsPay/alipay.vue`),
    meta: { title: "线下峰会支付宝支付" },
    hidden: true,
  },
  {
    path: `${prefix}/alipay/live/:order_id(\\d+)`,
    component: () => import(`@/components/LivePay/alipay.vue`),
    meta: { title: "峰会直播支付宝支付" },
    hidden: true,
  },
  
  // 访问不存在路由跳转到/
  {
    path: `${prefix}/:pathMatch(.*)*`,
    redirect: `${prefix}`,
  },
];

// 旧峰会
const oldEventlive = [
  {
    path: `${prefix}/eventlive`,
    component: () => import(`@/views/eventLive/${currLang}/layout/Index.vue`),
    hidden: true,
    children: [
      {
        path: "",
        component: () => import(`@/views/eventLive/${currLang}/Index.vue`),
        name: "",
        meta: {
          title: t("meta.首页"),
          icon: "icon-shouye",
          showFooter: true,
          index: 1,
        },
      },
      {
        path: `${prefix}/eventlive.html`,
        redirect: `${prefix}/eventlive`,
      },
      // type: 线下onsite, 直播live
      // host: 主办方 sponsor, 观众：visitor
      {
        path: `${prefix}/take-ticket/:host/:type/:give_id(\\d+)/:receive_code`,
        component: () => import(`@/views/eventLive/${currLang}/TakeTicket.vue`),
        name: "tackTicket",
        meta: { title: "领取赠票", showFooter: false, show_nav: 0, index: 1 },
      },
      {
        path: `${prefix}/forumticket`,
        component: () =>
          import(`@/views/eventLive/${currLang}/OnsiteForums/Index.vue`),
        name: "onsiteForums",
        meta: { title: "线下峰会", showFooter: true, index: 2 },
      },
      {
        path: `${prefix}/forumticket/:id(\\d+)`,
        component: () =>
          import(`@/views/eventLive/${currLang}/OnsiteForums/Info.vue`),
        name: "onsiteForums_info",
        meta: { title: "线下峰会详情", showFooter: true, index: 2 },
      },
      {
        path: `${prefix}/forumticket/:id(\\d+).html`,
        redirect: { name: "onsiteForums_info" },
      },
      {
        path: "livelist",
        component: () =>
          import(`@/views/eventLive/${currLang}/liveList/Index.vue`),
        name: "liveList",
        meta: { title: "直播峰会", showFooter: true, index: 3 },
      },
      {
        path: "livelist/info/:id(\\d+)",
        component: () =>
          import(`@/views/eventLive/${currLang}/liveList/Info.vue`),
        name: "",
        meta: { title: "直播峰会详情", showFooter: true, index: 3 },
      },
      {
        path: "livelist/info/:id(\\d+).html",
        redirect: "livelist/info/:id(\\d+)",
      },
      {
        path: "master",
        component: () =>
          import(`@/views/eventLive/${currLang}/liveList/Master.vue`),
        name: "master",
        meta: { title: "直播峰会", showFooter: true, index: 4 },
      },

      {
        path: `${prefix}/wxpay/live/:order_id(\\d+)`,
        component: () => import(`@/components/LivePay/wxpay.vue`),
        meta: { title: "线下峰会微信支付" },
        hidden: true,
      },

      {
        path: `${prefix}/wxpay/onsiteforums/:ticket_id(\\d+)`,
        component: () => import(`@/components/OnsiteForumsPay/wxpay.vue`),
        meta: { title: "线下峰会微信支付" },
        hidden: true,
      },

      // 访问不存在路由跳转到/
      {
        path: `${prefix}/eventlive/:pathMatch(.*)*`,
        redirect: `${prefix}/eventlive`,
      },
    ],
  },
];

// 新峰会购票
const newEventlive = [
  {
    path: `${prefix}/neweventlive`,
    component: () => import(`@/views/newEventLive/layout/Index.vue`),
    meta: { title: t("newelive.首页"), navIndex: 1 },
    hidden: true,
    children: [
      {
        path: "",
        component: () => import(`@/views/newEventLive/Index/Index.vue`),
        name: "neweventlive_index",
        meta: { title: t("newelive.首页"), navIndex: 1 },
      },
      {
        path: "summit",
        meta: { title: t("newelive.食品产业峰会"), navIndex: 2 },
        name: "neweventlive_summit",
        children: [
          {
            path: "",
            component: () => import(`@/views/newEventLive/Summit/Index.vue`),
          },
          {
            path: ":id(\\d+)",
            component: () => import(`@/views/newEventLive/Summit/Info.vue`),
            name: "summit_info",
            meta: { title: "", navIndex: 2 },
          },
          {
            path: "ticket/:id(\\d+)",
            component: () => import(`@/views/newEventLive/BuyTicket/Index.vue`),
            name: "summit_ticket_type",
            meta: { title: t("newelive.选择票种"), navIndex: 2 },
          },
          {
            path: `payment/:order_id(\\d+)`,
            component: () => import(`@/views/newEventLive/BuyTicket/Payment.vue`),
            name: "summit_ticket_confrim",
            meta: { title: t("newelive.支付"), navIndex: 2 },
          },
          {
            path: `alipay/:order_id(\\d+)`,
            component: () => import(`@/views/newEventLive/BuyTicket/alipay.vue`),
            meta: { title: "支付宝支付" },
            hidden: true,
          },
          {
            path: `wxpay/:order_id(\\d+)`,
            component: () => import(`@/views/newEventLive/BuyTicket/wxpay.vue`),
            meta: { title: "微信端支付" },
            hidden: true,
          },
          {
            path: `success/:order_id(\\d+)`,
            component: () => import(`@/views/newEventLive/BuyTicket/Success.vue`),
            meta: { title: t("newelive.购票成功"), navIndex: 2 },
            hidden: true,
          },
        ]
      },
      {
        path: `myticket`,
        meta: { title: t("newelive.我的门票"), navIndex: 3 },
        name: "neweventlive_myticket",
        hidden: true,
        children: [
          {
            path: "",
            component: () => import(`@/views/newEventLive/MyTicket/Index.vue`),
            hidden: true,
          },
          {
            path: `writeoff/:summit_id(\\d+)`,
            component: () => import(`@/views/newEventLive/MyTicket/WriteOff.vue`),
            meta: { title: t("newelive.门票核销"), navIndex: 3 },
            name: "myticket_writeoff",
            hidden: true,
          },
        ]
      },
      {
        path: `myorder`,
        component: () => import(`@/views/newEventLive/MyOrder/Index.vue`),
        meta: { title: t("newelive.我的订单"), navIndex: 4 },
        name: "neweventlive_myorder",
        hidden: true,
      },
      {
        path: `invoice`,
        component: () => import(`@/views/newEventLive/MyOrder/Invoice.vue`),
        meta: { title: t("newelive.发票信息"), navIndex: 5 },
        name: "neweventlive_invoice",
        hidden: true,
      },
      {
        path: `profile`,
        component: () => import(`@/views/newEventLive/MyProfile/Index.vue`),
        meta: { title: t("newelive.个人信息"), navIndex: 6 },
        name: "neweventlive_profile",
        hidden: true,
      },
    ],
  },
  {
    path: `${prefix}/neweventlive/login`,
    name: "neweventlive_login",
    component: () => import(`@/views/newEventLive/auth/Login.vue`),
    hidden: true,
  },

  

  // 门票核销
  {
    path: "/neweventlive/verification",
    hidden: true,
    children: [
      {
        path: "login",
        component: () => import("@/views/newEventLive/Verification/login.vue"),
        name: "verification_login",
        meta: { title: "门票核销"},
      },
      {
        path: "",
        component: () => import("@/views/newEventLive/Verification/content.vue"),
        name: "verification",
        meta: { title: "门票核销"},
      },
    ]
  },

  // 访问不存在路由跳转到/
  {
    path: `${prefix}/neweventlive/:pathMatch(.*)*`,
    redirect: `${prefix}/neweventlive`,
  },
];

export const constantRoutes = routes.concat(oldEventlive, newEventlive);

// 权限路由
export const asyncRoutes = [
  // 会员信息
  {
    path: `${prefix}/profile`,
    component: Layout,
    meta: { title: t("meta.会员信息"), icon: "icon-user" },
    children: [
      {
        path: ":expo_area_id(\\d+)?",
        component: () =>
          import(`@/views/visitor/${currLang}/Profile/Index.vue`),
        name: "Profile",
        meta: { title: t("meta.个人信息") },
      },
      // {
      //   path: `${prefix}/researchInfo`,
      //   hidden: true,
      //   component: () => import(`@/views/visitor/${currLang}/Profile/researchInfo.vue`),
      //   meta: { title: "调研信息"},
      // },
      {
        path: `${prefix}/changepwd`,
        component: () =>
          import(`@/views/visitor/${currLang}/Profile/changePwd.vue`),
        name: "Changepwd",
        meta: { title: t("meta.修改密码") },
      },
    ],
  },

  // 参观信息
  {
    path: `${prefix}/confirmation`,
    component: Layout,
    meta: {
      title: t("meta.参观信息"),
      icon: "icon-form",
      roles: ["visitor"],
    },
    children: [
      {
        path: ":expo_area_id?",
        component: () =>
          import(`@/views/visitor/${currLang}/Fair/Confirmation.vue`),
        meta: { title: t("meta.参观确认函"), chkUserInfo: true },
        name: "Confirmation",
      },
      {
        path: `${prefix}/survey/:expo_area_ids?`,
        component: () => import(`@/views/visitor/${currLang}/Fair/Survey.vue`),
        meta: { title: t("meta.问卷信息") },
      },
    ],
  },

  // 邮件订阅
  {
    path: `${prefix}/subscription`,
    component: Layout,
    // hidden: true,
    //暂时隐藏英文订阅
    meta: {
      lang: ["cn"],
    },
    children: [
      {
        path: "",
        component: () =>
          import(`@/views/visitor/${currLang}/Subscription/Index.vue`),
        meta: {
          title: t("meta.信息订阅"),
          icon: "icon-youjian",
          chkUserInfo: true,
          roles: ["visitor", "ordinary"],
        },
      },
    ],
  },

  // 商贸配对
  {
    path: `${prefix}/matching`,
    component: Layout,
    meta: {
      title: t("meta.商贸配对"),
      icon: "icon-goutong",
      chkUserInfo: true,
      roles: ["visitor"],
    },
    // hidden: true,
    children: [
      {
        path: "form",
        component: () =>
          import(`@/views/visitor/${currLang}/Booking/formInfo.vue`),
        meta: { title: t("meta.配对信息") },
      },
      {
        path: "",
        component: () =>
          import(`@/views/visitor/${currLang}/Booking/Index.vue`),
        meta: { title: t("meta.在线预约") },
      },
      {
        path: `${prefix}/matching/booking/sent`,
        meta: { title: t("meta.预约管理") },
        children: [
          {
            path: "",
            component: () =>
              import(`@/views/visitor/${currLang}/Booking/myBooking.vue`),
            meta: { title: t("meta.发起的约见") },
            hidden: true,
          },
          {
            path: `${prefix}/matching/booking/receive`,
            component: () =>
              import(`@/views/visitor/${currLang}/Booking/receiveBooking.vue`),
            meta: { title: t("meta.收到的约见") },
            hidden: true,
          },
          {
            path: `${prefix}/matching/booking/schedule`,
            component: () =>
              import(`@/views/visitor/${currLang}/Booking/schedule.vue`),
            meta: { title: t("meta.我的日程") },
            hidden: true,
          },
        ],
      },
      {
        path: `${prefix}/matching/favlist/exhibitor`,
        meta: { title: t("meta.我的收藏") },
        children: [
          {
            path: `${prefix}/matching/favlist/exhibitor`,
            component: () =>
              import(`@/views/visitor/${currLang}/Booking/favlist_exh.vue`),
            meta: { title: t("meta.收藏展商") },
            hidden: true,
          },
          {
            path: `${prefix}/matching/favlist/products`,
            component: () =>
              import(
                `@/views/visitor/${currLang}/Booking/favlist_products.vue`
              ),
            meta: { title: t("meta.收藏展品") },
            hidden: true,
          },
        ],
      },
    ],
  },

  // 邀请管理
  {
    path: `${prefix}/invite`,
    component: Layout,
    meta: {
      title: t("meta.邀请管理"),
      icon: "icon-yaoqing",
      chkUserInfo: true,
      roles: ["visitor"],
    },

    children: [
      {
        path: "recommend",
        component: () =>
          import(`@/views/visitor/${currLang}/Invite/Recommend.vue`),
        meta: { title: t("meta.推荐给好友") },
      },
      {
        path: `${prefix}/pasvreg`,
        meta: { title: t("meta.协助他人登记") },
        children: [
          {
            path: ":expo_area_id(\\d+)?",
            component: () =>
              import(`@/views/visitor/${currLang}/Pasvreg/index.vue`),
            meta: { title: t("meta.协助他人登记"), breadcrumb: false },
            name: "Pasvreg",
          },
          {
            path: "add/:expo_area_id(\\d+)?",
            component: () =>
              import(`@/views/visitor/${currLang}/Pasvreg/Add.vue`),
            meta: { title: t("meta.协助他人登记"), breadcrumb: false },
            hidden: true,
          },
          {
            path: "info/:expo_area_id(\\d+)/:id(\\d+)",
            component: () =>
              import(`@/views/visitor/${currLang}/Pasvreg/Info.vue`),
            meta: { title: t("meta.协助他人登记"), breadcrumb: false },
            hidden: true,
          },
          {
            path: "group/:expo_area_id(\\d+)?",
            component: () =>
              import(`@/views/visitor/${currLang}/Pasvreg/Group.vue`),
            meta: { title: t("meta.团体导入") },
            hidden: true,
          },
          {
            path: "group/detail/:expo_area_id(\\d+)/:batch_id",
            component: () =>
              import(`@/views/visitor/${currLang}/Pasvreg/groupDetail.vue`),
            meta: { title: t("meta.团体导入") },
            hidden: true,
          },
        ],
      },
    ],
  },

  // 峰会直播
  {
    path: `${prefix}/live`,
    component: Layout,
    meta: {
      title: t("meta.峰会直播"),
      icon: "icon-icon_zhibo-xian",
      chkUserInfo: true,
    },
    // hidden: true,

    children: [
      {
        path: "",
        component: () => import(`@/views/visitor/${currLang}/Live/Index.vue`),
        meta: { title: t("meta.峰会直播") },
      },
      {
        path: "info/:id(\\d+)",
        component: () => import(`@/views/visitor/${currLang}/Live/Info.vue`),
        meta: { title: t("meta.峰会直播") },
        hidden: true,
      },
      {
        path: "orders",
        component: () => import(`@/views/visitor/${currLang}/Live/Orders.vue`),
        meta: { title: t("meta.我的订单") },
      },
      {
        path: "freeticket",
        component: () =>
          import(`@/views/visitor/${currLang}/Live/Freeticket/Index.vue`),
        meta: { title: t("meta.我的赠票") },
      },
      {
        path: "freeticket/info/:give_id(\\d+)",
        component: () =>
          import(`@/views/visitor/${currLang}/Live/Freeticket/Info.vue`),
        meta: { title: "赠票领取人" },
        hidden: true,
      },
      {
        path: "reservation",
        component: () =>
          import(`@/views/visitor/${currLang}/Live/Reservation.vue`),
        meta: { title: t("meta.我的订阅") },
      },
    ],
  },

  // 外链接线下峰会
  {
    path: "/",
    meta: {
      title: t("meta.线下峰会"),
      icon: "icon-outline-message-question",
      chkUserInfo: true,
    },
    children: [
      {
        path: `https://${host_domain[currLang]}/neweventlive`,
        meta: {
          title: t("meta.线下峰会"),
          icon: "icon-outline-message-question",
        },
      }
    ]
  },

  // 线下峰会
  {
    path: `${prefix}/onsite`,
    component: Layout,
    meta: {
      title: t("meta.线下峰会"),
      icon: "icon-outline-message-question",
      chkUserInfo: true,
    },
    hidden: true,

    children: [
      {
        path: "",
        redirect: `${prefix}/onsite/forums`,
      },
      {
        path: "forums",
        component: () => import(`@/views/visitor/${currLang}/Onsite/Index.vue`),
        meta: { title: t("meta.峰会购票") },
      },
      {
        path: "forums/:id(\\d+)",
        component: () => import(`@/views/visitor/${currLang}/Onsite/Info.vue`),
        meta: { title: t("meta.峰会购票") },
        hidden: true,
      },
      {
        path: "orders",
        component: () =>
          import(`@/views/visitor/${currLang}/Onsite/Orders.vue`),
        meta: { title: t("meta.我的订单") },
      },
      {
        path: "tickets",
        component: () =>
          import(`@/views/visitor/${currLang}/Onsite/Tickets.vue`),
        meta: { title: t("meta.我的门票") },
      },
      {
        path: "noredeemed",
        component: () =>
          import(`@/views/visitor/${currLang}/Onsite/noRedeem.vue`),
        name: "",
        meta: { title: "未兑换门票", lang: ["cn"] },
      },

      {
        path: "freeticket",
        component: () =>
          import(`@/views/visitor/${currLang}/Onsite/Freeticket/Index.vue`),
        meta: { title: t("meta.我的赠票") },
      },
      {
        path: "freeticket/info/:ticket_id(\\d+)",
        component: () =>
          import(`@/views/visitor/${currLang}/Onsite/Freeticket/Info.vue`),
        meta: { title: t("meta.我的赠票") },
        hidden: true,
      },
    ],
  },

  // 白皮书下载
  {
    path: `${prefix}/download`,
    component: Layout,
    meta: {
      title: t("meta.白皮书下载"),
      icon: "icon-shu2",
      chkUserInfo: true,
      roles: ["visitor", "ordinary"],
    },

    children: [
      {
        path: "",
        component: () =>
          import(`@/views/visitor/${currLang}/Download/Index.vue`),
        meta: { title: t("meta.白皮书下载"), icon: "icon-shu2" },
      },
      {
        path: `${prefix}/mydownload`,
        component: () =>
          import(`@/views/visitor/${currLang}/Download/myDownload.vue`),
        meta: { title: t("meta.我的下载") },
        hidden: true,
      },
    ],
  },

  // 沙龙
  {
    path: "/",
    meta: {
      title: "线下沙龙",
      icon: "icon-See",
      roles: ["visitor", "ordinary"],
      lang: ["cn"],
    },
    children: [
      {
        path: `https://${website[currLang]}/activites/meetime.html`,
        meta: {
          title: "线下沙龙",
          icon: "icon-See",
          roles: ["visitor", "ordinary"],
          lang: ["cn"],
        },
      },
    ],
  },

  {
    path: `${prefix}/salon`,
    component: Layout,
    meta: {
      title: t("meta.沙龙报名"),
      icon: "icon-lianxiwomen",
      chkUserInfo: true,
      roles: ["visitor", "ordinary"],
      lang: ["cn"],
    },
    hidden: true,

    children: [
      {
        path: "",
        component: () => import(`@/views/visitor/${currLang}/Salon/Index.vue`),
        meta: {
          title: t("meta.沙龙报名"),
          icon: "icon-lianxiwomen",
          lang: ["cn"],
        },
      },
      {
        path: `${prefix}/mysalon`,
        component: () =>
          import(`@/views/visitor/${currLang}/Salon/Mysalon.vue`),
        meta: { title: t("meta.沙龙报名") },
        hidden: true,
      },
    ],
  },

  // 发票申请
  {
    path: `${prefix}/invoice`,
    component: Layout,
    meta: {
      title: t("meta.发票申请"),
      icon: "icon-fapiaoguanli",
      chkUserInfo: true,
      lang: ["cn"],
    },

    children: [
      {
        path: ":source_type?",
        component: () =>
          import(`@/views/visitor/${currLang}/Invoice/Index.vue`),
        meta: {
          title: t("meta.发票申请"),
          icon: "icon-fapiaoguanli",
          lang: ["cn"],
        },
      },
      {
        path: "add/:source_type(\\d+)/:id(\\d+)",
        component: () => import(`@/views/visitor/${currLang}/Invoice/Add.vue`),
        meta: { title: t("meta.发票申请") },
        hidden: true,
      },
      {
        path: "info/:source_type(\\d+)/:id(\\d+)",
        component: () => import(`@/views/visitor/${currLang}/Invoice/Info.vue`),
        meta: { title: t("meta.发票申请") },
        hidden: true,
      },
    ],
  },

  // 展馆交通
  {
    path: "/",
    children: [
      {
        path: `https://${website[currLang]}/contact/arrived.html`,
        meta: {
          title: t("meta.展馆交通"),
          icon: "icon-See",
          roles: ["visitor", "ordinary"],
        },
      },
    ],
  },

  // 联系我们
  {
    path: "/",
    children: [
      {
        path: `https://${website[currLang]}/contact/visitor-media.html`,
        meta: { title: t("meta.联系主办方"), icon: "icon-ziyuan" },
      },
    ],
  },

  {
    path: `${prefix}/visitor/:page`,
    redirect: (to) => {
      const page = to.params.page;
      return page;
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  base: "/",
});

// 白名单 name 值
const whiteList = [
  "login",
  "reg",
  "regVip",
  "regInvite",
  "forget",
  "regUniversal",
  "policy",
  "info_subscription",
  "wechat",
  "wxbind",
  "logintest",
  "verification",
  "verification_login"
];

// 添加权限路由
router.beforeEach(async (to, from, next) => {
  console.log("beforeEach----------", to);
  // next();
 
  if(to.query.v_token) {
    if (currLang == "en") {
      await request({
        url: "/setVisitorToken",
        method: "post",
        data: {
          app_lang: currLang,
          "v-token": to.query.v_token,
        },
      })
        .then(() => {
          Cookies.set("isLogin", true);
        })
        .catch(() => {});
    }
  }
  
  // 用户是否登录
  if (Cookies.get("isLogin")) {
    console.log("已登录----------");
    //已登录
    if (to.name == "login") {
      // 重定向至首页
      next({ path: `${prefix}` });
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      console.log("是否加载路由", hasRoles, store.getters.roles);
      if (hasRoles) {
        if (/(\/visitor)?(\/en)?(\/reg)(?![_])(\/\d\w)?/.test(to.path)) {
          next({ path: `${prefix}` });
        } else {
          next();
        }
      } else {
        try {
          /**
           * 英文主站和观众登录状态互通
           * 英文主站登录手动记录凭证 vst_token
           */
          if (Cookies.get("vst_token") && currLang == "en") {
            await request({
              url: "/setVisitorToken",
              method: "post",
              data: {
                app_lang: currLang,
                "v-token": Cookies.get("vst_token"),
              },
            })
              .then(() => {})
              .catch(() => {});
          }

          const roles = await store.dispatch("user/getInfo");
          console.log("加载路由-roles--", roles, to);
          store.dispatch("permission/generateRoutes", roles);
          next({ ...to, replace: true });
        } catch (error) {
          // 重新登录
          showMsg.error("请重新登录");
          await store.dispatch("user/logout");
          // next(`/login?redirect=${to.path}`);
          next(`${prefix}/login`);
        }
      }
    }
  } else {
    console.log("未登录----------", to);
    // 未登录
    if (whiteList.includes(to.name)) {
      next();
    } else {
      var liveReg = /(\/en)?\/eventlive\/*/;
      var newliveReg = /(\/en)?\/neweventlive\/*/;
      var forumticketReg = /(\/en)?\/forumticket\/*/;
      var payReg = /(\/en)?(\/alipay\/*)|(\/wxpay\/*)/;
      var ticketReg = /(\/en)?\/take-ticket\/*/;
      // 特殊情况
      if (
        liveReg.test(to.path) ||
        newliveReg.test(to.path) ||
        forumticketReg.test(to.path) ||
        payReg.test(to.path) ||
        ticketReg.test(to.path)
      ) {
        next();
      } else {
        // next({ path: `${prefix}/login`, query: { redirect: to.path } });
        next({ path: `${prefix}/login` });
      }
    }
  }
});

export default router;
